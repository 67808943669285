












































































































import Vue from 'vue';
import * as Api from '@/api/dictionaryList';
import moment from 'moment';
import { toString } from 'lodash';
const columns = [
  {
    title: '字典编码',
    dataIndex: 'dictCode',
    key: 'dictCode',
    align: 'center',
  },
  {
    title: '字典key',
    dataIndex: 'entryKey',
    key: 'entryKey',
    align: 'center',
  },
  {
    title: '字典值',
    dataIndex: 'entryValue',
    key: 'entryValue',
    align: 'center',
  },
  {
    title: '排序',
    dataIndex: 'orderNum',
    key: 'orderNum',
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    width: '240px',
    ellipsis: true,
  },
  {
    title: '创建时间',
    dataIndex: 'recDate',
    key: 'recDate',
    align: 'center',
    scopedSlots: { customRender: 'recDate' },
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
const dataSource = [];
export default Vue.extend({
  name: 'dictList',
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      loading: false,
      valueForm: {
        entryValue: '',
        dictCode: '',
        entryKey: '',
        orderNum: '',
        description: '',
      },
      rules: {
        dictCode: [
          {
            required: true,
            message: '请输入字典编码',
            trigger: 'change',
          },
        ],
        entryKey: [
          {
            required: true,
            message: '请输入字典Key',
            trigger: 'change',
          },
        ],
        entryValue: [
          {
            required: true,
            message: '请输入字典值',
            trigger: 'change',
          },
        ],
      },
      tableLoading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      formState: {
        entryKey: '',
        entryValue: '',
      },
      dataSource,
      columns,
      valueFormVisible: false,
      type: false,
      deleteVisible: false,
      deleteId: '',
    };
  },
  created() {
    this.handleSearchSubmit();
  },
  methods: {
    moment,
    // 查询按钮
    getDictList() {
      this.valueForm.dictCode = toString(this.$route.query.id);
      const param = {
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        dictCode: this.valueForm.dictCode,
        entryKey: this.formState.entryKey,
        entryValue: this.formState.entryValue,
      };
      this.tableLoading = true;
      Api.searchDictValue(param)
        .then((res) => {
          const data = res;
          if (data.status === 200) {
            this.dataSource = res.result.list;
            this.pagination.total = res.result.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    reset() {
      this.formState.entryKey = '';
      this.formState.entryValue = '';
      this.handleSearchSubmit();
    },
    // 组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getDictList();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDictList();
    },
    // 新增字典值
    newForm(record) {
      this.valueFormVisible = true;
      if (record === 0) {
        this.type = false;
        (this.valueForm.entryValue = ''),
          (this.valueForm.orderNum = ''),
          (this.valueForm.entryKey = ''),
          (this.valueForm.description = '');
      } else {
        this.valueForm = record;
        this.type = true;
      }
    },
    /**
     * @description: 新增字典值表单提交
     * @param {*}
     * @return {*}
     **/
    onSubmit() {
      (this.$refs.ruleForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          if (this.type === false) {
            Api.dictionaryEntry(this.valueForm)
              .then((res) => {
                if (res) {
                  this.loading = true;
                  this.handleSearchSubmit();
                  this.close();
                }
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            const params = {
              sequenceNbr: this.valueForm['sequenceNbr'],
              description: this.valueForm.description,
              entryValue: this.valueForm.entryValue,
              orderNum: this.valueForm.orderNum,
              entryKey: this.valueForm.entryKey,
              dictCode: this.valueForm.dictCode,
            };
            Api.editDictValue(params)
              .then((res) => {
                if (res) {
                  this.loading = true;
                  this.handleSearchSubmit();
                  this.close();
                }
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    deleteConfirm(record) {
      this.deleteVisible = true;
      this.deleteId = record.sequenceNbr;
    },
    deleteValue() {
      Api.deleteDictValue([this.deleteId])
        .then((res) => {
          if (res) {
            this.loading = true;
            this.deleteVisible = false;
            this.handleSearchSubmit();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * @description: 表单关闭
     * @param {*}
     * @return {*}
     **/
    close() {
      this.valueFormVisible = false;
      this.getDictList();
    },
  },
});
